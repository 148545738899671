import { Banner } from "@shopify/polaris";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import styled from "styled-components";

const BlackSupportLink = styled.span`
  text-decoration: underline;
  color: var(--p-color-text) !important;
  cursor: pointer;
`;

export const ContactSupportBanner = ({ widgetType }: { widgetType: "customer portal" | "subscription" }) => {
  return (
    <span data-testId="contact-support-banner">
      <Banner status="warning">
        It looks like your theme doesn&apos;t support the block for our Smartrr {widgetType} widget. Please use
        another theme or contact{" "}
        <BlackSupportLink
          onClick={() => navigateWithShopInQuery(`${adminRoutePrefix}/support`, undefined, undefined, true)}
        >
          support
        </BlackSupportLink>
        .
      </Banner>
    </span>
  );
};
