import { useApolloClient } from "@apollo/client";
import { Banner, Button, ContextualSaveBar, Layout, LegacyStack, Link, Modal, Page } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { adminConfigRoutePrefix, adminRoutePrefix } from "@smartrr/shared/constants";
import {
  ISellingPlanFromZodWithId,
  ISellingPlanGroupCreateWithId,
  ISellingPlanGroupInputForZod,
  ISmartrrBundleConfig,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { DisplayableError, UserError } from "@smartrr/shared/shopifyGraphQL/api";
import {
  mutationShopifyDeliveryProfileUpdate,
  paginatedQueryShopifyDeliveryProfiles,
} from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { ensureShopifyGid, extractShopifyId, viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import { capitalize, cloneDeep, isEmpty, isEqual, omit, set } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SequentialOffering } from "@vendor-app/app/_sharedComponents/Sequential";
import {
  SequentialGroupContext,
  SequentialGroupContextType,
} from "@vendor-app/app/_sharedComponents/Sequential/SequentialGroupContext";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { BundleConfigAccess } from "@vendor-app/app/_state/zustand/BundleConfigStore";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { useLocation } from "@vendor-app/utils/useLocation";

import { SellingPlanBundle } from "../components/SellingPlanBundle";
import { SellingPlanGroup } from "../components/SellingPlanGroup";
import { SellingPlanProducts } from "../components/SellingPlanProducts";
import { subscriptionsMatchingFilter, useConfirmationModalReducer } from "../libs";
import { sortSellingPlansByCreatedDate } from "../utils";
import { oneLine } from "common-tags";
import { SetupStoreAccess } from "../../AdminSetupRoute/libs/store";

interface Props {
  productIds: string[];
  variantIds: string[];
  sellingPlanGroup: ISellingPlanGroupInputForZod;
  sellingPlans: ISellingPlanFromZodWithId[];

  onSave(sellingPlanGroup: ISellingPlanGroupCreateWithId, sellingPlans: ISellingPlanFromZodWithId[]): void;

  onDiscard(): void;

  onDelete(id: string): Promise<boolean>;

  doSellingPlanGroupsExist: boolean;
}

const BottomButtonGroup = styled.div.attrs((props: { isDeleteButton: boolean }) => props)`
  box-shadow: inset 0 1px 0 #e4e5e7;
  display: flex;
  justify-content: ${props => (props.isDeleteButton ? "space-between" : "flex-end")};
  padding: 20px 0;
`;

export const RemoveModalStyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    padding: 4px;
  }
`;

export function AdminSellingPlanGroupRouteWithData({
  sellingPlanGroup: initialSellingPlanGroup,
  sellingPlans: initialSellingPlans,
  onSave: onSaveCallback,
  onDiscard: onDiscardCallback,
  productIds: initialProductIds,
  variantIds: initialVariantIds,
  doSellingPlanGroupsExist,
  onDelete: onDeleteCallback,
}: Props): React.JSX.Element {
  const apolloClient = useApolloClient();
  const { addToast } = useToast();

  const setup = SetupStoreAccess.useSetup()!;
  const setupActions = SetupStoreAccess.useActions();

  const isBundleConfigsLoading = BundleConfigAccess.useLoading();
  const allBundleConfigs = BundleConfigAccess.useConfig();
  const bundleConfigActions = BundleConfigAccess.useActions();

  const groupActions = SellingPlanGroupStoreAccess.useActions();

  const dispatch = useSmartrrVendorDispatch();

  const location = useLocation();

  const [sellingPlanGroupInput, setSellingPlanGroupInput] = useState<ISellingPlanGroupInputForZod>({
    ...cloneDeep(initialSellingPlanGroup),
  });

  const sortedInitialSellingPlans = useMemo(
    () => sortSellingPlansByCreatedDate(initialSellingPlans),
    [initialSellingPlans]
  );

  const [sellingPlanInputs, setSellingPlanInputs] = useState<ISellingPlanFromZodWithId[]>(
    sortedInitialSellingPlans.map(p => cloneDeep(p))
  );

  const [productIds, setProductIds] = useState(initialProductIds);
  const [variantIds, setVariantIds] = useState<string[]>(initialVariantIds);

  const [hasChanges, setHasChanges] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [errors, setErrors] = useState<DisplayableError[]>([]);
  const clearErrors = useCallback(() => {
    groupActions.clearValidationErrors();
    setErrors([]);
  }, [setErrors, groupActions.clearValidationErrors]);

  const {
    modalState: {
      isModalOpen: isConfirmationModalOpen,
      method: confirmationModalFunction,
      title: confirmationModalTitle,
      message: confirmationModalMessage,
      confirmActionMessage: confirmationModalConfirmActionMessage,
      subscriptions: confirmationModalSubscriptions,
    },
    closeModal: closeConfirmationModal,
    openModal: openConfirmationModal,
  } = useConfirmationModalReducer();

  const [showLinkedBundleModal, setShowLinkedBundleModal] = useState<boolean>(false);

  const isPrepaidPlan = useMemo(
    () =>
      Boolean(
        sellingPlanInputs.filter(
          item => item.deliveryPolicy?.recurring?.intervalCount! < item.billingPolicy?.recurring?.intervalCount!
        ).length
      ),
    [sellingPlanInputs]
  );

  const bundleConfigs = useMemo(() => {
    return allBundleConfigs.filter(
      (b: ISmartrrBundleConfig) => b.sellingPlanGroupId === sellingPlanGroupInput.shopifyId
    );
  }, [sellingPlanGroupInput.shopifyId, allBundleConfigs]);

  useEffect(() => {
    void bundleConfigActions.fetchBundleConfig();
  }, []);

  useEffect(() => {
    const groupHasChanges = !isEqual(initialSellingPlanGroup, sellingPlanGroupInput);
    const plansHaveChanges = !isEqual(sortedInitialSellingPlans, sellingPlanInputs);
    const productsHaveChanges = !isEqual([...initialProductIds].sort(), [...productIds].sort());
    const variantsHaveChanges = !isEqual([...initialVariantIds].sort(), [...variantIds].sort());
    setHasChanges(groupHasChanges || plansHaveChanges || productsHaveChanges || variantsHaveChanges);
  }, [
    initialSellingPlanGroup,
    sellingPlanGroupInput,
    sortedInitialSellingPlans,
    sellingPlanInputs,
    initialVariantIds,
    initialProductIds,
    variantIds,
    productIds,
  ]);

  const numericGroupId = useMemo(
    () => Number(extractShopifyId(sellingPlanGroupInput.shopifyId || undefined) ?? 0),
    [sellingPlanGroupInput.shopifyId]
  );

  const isCreatingNewSellingPlanGroup = !sellingPlanGroupInput.id;

  const updateSellingPlan = useCallback(
    (index: number, properties: Record<string, any>) => {
      let sellingPlan = sellingPlanInputs[index];
      for (const [path, newValue] of Object.entries(properties)) {
        sellingPlan = set(sellingPlan, path, newValue);
      }

      setSellingPlanInputs([
        ...sellingPlanInputs.slice(0, index),
        sellingPlan,
        ...sellingPlanInputs.slice(index + 1),
      ]);
    },
    [sellingPlanInputs]
  );

  const deleteSellingPlan = useCallback((index: number) => {
    setSellingPlanInputs(existingInputs => [
      ...existingInputs.slice(0, index),
      ...existingInputs.slice(index + 1),
    ]);
  }, []);

  const onSave = useCallback(async () => {
    if (!sellingPlanGroupInput) {
      return;
    }

    clearErrors();
    setIsSaving(true);

    try {
      if (sellingPlanGroupInput.id) {
        const updatedGroupRes = await groupActions.updateSellingPlanGroup(sellingPlanGroupInput.id, {
          ...sellingPlanGroupInput,
          productIds,
          productVariantIds: variantIds,
          sellingPlans: sellingPlanInputs,
        });
        if (updatedGroupRes.result === "failure") {
          setIsSaving(false);
          addToast(updatedGroupRes.message, true);
          return;
        }

        const updatedGroup = updatedGroupRes.data;

        if (
          productIds &&
          setup.subscriptionSetup?.sellingPlan?.completed &&
          !setup.subscriptionSetup?.product?.completed &&
          setup.selectedSellingPlanId === sellingPlanGroupInput.id
        ) {
          setupActions.update([
            {
              path: "subscriptionSetup.product",
              val: { completed: true, demo: false, date: null },
            },
          ]);
        }

        setSellingPlanInputs(sortSellingPlansByCreatedDate(updatedGroup.sellingPlans));
        setSellingPlanGroupInput(omit(updatedGroup, ["sellingPlans", "productIds", "productVariantIds"]));

        setProductIds(updatedGroup.productIds);
        setVariantIds(updatedGroup.productVariantIds);

        onSaveCallback(updatedGroup, updatedGroup.sellingPlans);
        addToast("Program updated");
      } else {
        const createGroupRes = await groupActions.createSellingPlanGroup({
          ...sellingPlanGroupInput,
          productIds,
          productVariantIds: variantIds,
          sellingPlans: sellingPlanInputs,
        });

        if (createGroupRes.result === "failure") {
          addToast(createGroupRes.message, true);
          setIsSaving(false);
          return;
        }

        const newGroup = createGroupRes.data;

        setSellingPlanInputs(sortSellingPlansByCreatedDate(newGroup.sellingPlans));
        setSellingPlanGroupInput(omit(newGroup, ["sellingPlans", "productIds", "productVariantIds"]));

        setProductIds(newGroup.productIds);
        setVariantIds(newGroup.productVariantIds);

        onSaveCallback(newGroup, newGroup.sellingPlans);

        addToast("Program created");

        if (location.hash === "fromSetup") {
          setupActions.update([
            {
              path: "selectedSellingPlanId",
              val: newGroup.shopifyId || undefined,
            },
            {
              path: "subscriptionSetup.sellingPlan",
              val: {
                date: null,
                completed: true,
                demo: false,
              },
            },
          ]);

          redirectToSetup();
        } else {
          if (!doSellingPlanGroupsExist && !setup?.subscriptionSetup?.sellingPlan.completed) {
            setupActions.update([
              {
                path: "selectedSellingPlanId",
                val: newGroup.shopifyId || undefined,
              },
              {
                path: "subscriptionSetup.sellingPlan",
                val: {
                  date: null,
                  completed: true,
                  demo: false,
                },
              },
            ]);
          }

          redirectToSellingPlanGroup(extractShopifyId(newGroup.shopifyId || undefined) ?? "");
        }
      }
    } catch (error) {
      let message: string;
      try {
        const userErrors: UserError[] = JSON.parse(error.message);
        message = userErrors.map(userError => userError.message).join(", ");
      } catch (error) {
        message = error.message;
      }
      addToast(`Error: ${message}`, true);
      setIsSaving(false);
    }

    setIsSaving(false);
  }, [
    sellingPlanInputs,
    sellingPlanGroupInput,
    sortedInitialSellingPlans,
    onSaveCallback,
    setup,
    doSellingPlanGroupsExist,
    productIds,
    variantIds,
  ]);

  const redirectToSetup = useCallback(() => {
    navigateWithShopInQuery(`${adminRoutePrefix}/setup`, {}, { replace: true });
  }, [adminConfigRoutePrefix]);

  const redirectToSellingPlanGroup = useCallback(
    (sellingPlanIdNumber: string) => {
      navigateWithShopInQuery(
        `${adminConfigRoutePrefix}/plans/${sellingPlanIdNumber}`,
        {},
        {
          replace: true,
        }
      );
    },
    [adminConfigRoutePrefix]
  );

  const openInNewTab = async () => {
    for (const subscription of confirmationModalSubscriptions) {
      window.open(`${adminRoutePrefix}/subscriptions/${viewShopifyId(subscription.shopifyId)}`);
    }
  };

  const onDelete = useCallback(async () => {
    if (!isEmpty(bundleConfigs)) {
      setShowLinkedBundleModal(true);
      return;
    }

    if (!sellingPlanGroupInput.id) {
      addToast("Can't delete the subscription program", true);
      return;
    }

    const deleteSuccessful = await onDeleteCallback(sellingPlanGroupInput.id);

    if (deleteSuccessful) {
      if (
        setup.selectedSellingPlanId === sellingPlanGroupInput.shopifyId ||
        setup.selectedSellingPlanId === sellingPlanGroupInput.id
      ) {
        setupActions.update([
          { path: "selectedSellingPlanId", val: "" },
          { path: "subscriptionSetup.sellingPlan", val: { date: null, completed: false, demo: false } },
          { path: "subscriptionSetup.product", val: { date: null, completed: false, demo: false } },
        ]);
      }
      navigateWithShopInQuery(`${adminConfigRoutePrefix}/plans`);
    }
  }, [sellingPlanGroupInput.id, sellingPlanGroupInput.shopifyId, bundleConfigs, setup]);

  const onDiscard = useCallback(async () => {
    clearErrors();
    onDiscardCallback();
    setSellingPlanGroupInput({ ...initialSellingPlanGroup });
    setSellingPlanInputs(sortedInitialSellingPlans.map(p => cloneDeep(p)));
    setProductIds(initialProductIds);
    setVariantIds(initialVariantIds);
    await reloadDeliveryProfile();
  }, [initialSellingPlanGroup, sortedInitialSellingPlans, initialProductIds, initialVariantIds]);

  const onSellingPlanGroupUpdate = useCallback(
    (key: string, value: any) => setSellingPlanGroupInput(group => ({ ...group, [key]: value })),
    []
  );

  const onDeleteSellingPlan = async (index: number) => {
    const { id: sellingPlanId, shopifyId: sellingPlanShopifyId } = sellingPlanInputs[index];

    if (!sellingPlanShopifyId || !sellingPlanId) {
      // The selling plan either hasn't been saved or hasn't been sent to
      // Shopify. Either way, it won't be in use yet.
      deleteSellingPlan(index);
      return;
    }

    const subscriptionsForCurrentSellingPlan = await subscriptionsMatchingFilter({
      sellingPlanId: [sellingPlanShopifyId],
    });

    if (subscriptionsForCurrentSellingPlan.length === 0) {
      // The selling plan is not in use
      deleteSellingPlan(index);
      return;
    }

    openConfirmationModal(
      "Are you sure you want to delete this plan?",
      oneLine`
        You currently have customers subscribed to products using this selling
        plan. They'll still continue to receive deliveries, but won't be able
        to make any changes to their subscription from their Account Portal.
      `,
      "Delete Plan",
      () => deleteSellingPlan(index),
      subscriptionsForCurrentSellingPlan
    );
  };

  const onDeleteSellingPlanGroup = async () => {
    const sellingPlanIds = sellingPlanInputs.map(input => input.id).filter((i): i is string => Boolean(i));

    const sellingPlanShopifyIds = sellingPlanInputs
      .map(input => input.shopifyId)
      .filter((i): i is string => Boolean(i));

    if (sellingPlanShopifyIds.length === 0 || sellingPlanIds.length === 0) {
      // The selling plans either haven't been saved or haven't been sent to
      // Shopify. Either way, none of them will be in use yet.
      void onDelete();
      return;
    }

    const subscriptionsForCurrentSellingPlanGroup = await subscriptionsMatchingFilter({
      sellingPlanId: sellingPlanShopifyIds,
    });

    if (subscriptionsForCurrentSellingPlanGroup.length === 0) {
      // None of the selling plans are in use
      void onDelete();
      return;
    }

    openConfirmationModal(
      "Are you sure you want to delete this program?",
      oneLine`
        You currently have customers subscribed to products using this
        subscription program. They'll still continue to receive deliveries,
        but won't be able to make any changes to their subscription from their
        Account Portal.
      `,
      "Delete Program",
      onDelete,
      subscriptionsForCurrentSellingPlanGroup
    );
  };

  const reloadDeliveryProfile = async () => {
    const res = await paginatedQueryShopifyDeliveryProfiles(apolloClient);

    const deliveryProfilesToUpdate = res.filter(profile =>
      profile.sellingPlanGroups.edges.some(spg => spg.node.id === sellingPlanGroupInput.id)
    );
    for (const deliveryProfile of deliveryProfilesToUpdate) {
      if (sellingPlanGroupInput.id) {
        await mutationShopifyDeliveryProfileUpdate(
          apolloClient,
          ensureShopifyGid("DeliveryProfile", deliveryProfile.id),
          { sellingPlanGroupsToDissociate: [sellingPlanGroupInput.id] }
        );
        await mutationShopifyDeliveryProfileUpdate(
          apolloClient,
          ensureShopifyGid("DeliveryProfile", deliveryProfile.id),
          { sellingPlanGroupsToAssociate: [sellingPlanGroupInput.id] }
        );
      }
    }
  };

  const closeLinkedBundleModal = useCallback(() => setShowLinkedBundleModal(false), []);

  const sequentialGroupContext = useMemo((): SequentialGroupContextType => {
    return {
      isPrepaid: isPrepaidPlan,
      numericShopifyId: numericGroupId,
      numberOfPlans: sellingPlanInputs.length,
      isCreatingNewProgram: isCreatingNewSellingPlanGroup,
    };
  }, [isPrepaidPlan, numericGroupId, sellingPlanInputs, isCreatingNewSellingPlanGroup]);

  const DeleteBundleBanner = (): React.JSX.Element => (
    <Banner title="" status="critical">
      <p>
        {`You currently have bundles linked to this subscription program. This subscription program can’t be
            deleted until the following linked bundles are deleted:`}
      </p>
      &nbsp;
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {bundleConfigs.map(b => (
          <li style={{ color: "#2C6ECB" }} key={b.id}>
            <Link onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles/${b.id}`)}>
              {b.name}
            </Link>
          </li>
        ))}
      </ul>
    </Banner>
  );

  return (
    <SequentialGroupContext.Provider value={sequentialGroupContext}>
      <Page
        narrowWidth
        backAction={{
          content: "Return to all Subscription Programs",
          url: `${adminConfigRoutePrefix}/plans`,
        }}
        title={
          isCreatingNewSellingPlanGroup
            ? "Creating subscription program"
            : `Managing ${sellingPlanGroupInput.storefrontLabel}`
        }
      >
        {hasChanges ? (
          <ContextualSaveBar
            message="Unsaved changes"
            discardAction={{
              content: "Discard",
              onAction: onDiscard,
            }}
            saveAction={{
              loading: isSaving,
              content: "Save",
              onAction: onSave,
            }}
          />
        ) : null}
        <LegacyStack vertical>
          <SellingPlanGroup
            sellingPlanGroup={sellingPlanGroupInput!}
            sellingPlans={sellingPlanInputs}
            onSellingPlanGroupUpdate={onSellingPlanGroupUpdate}
            onDeleteSellingPlan={onDeleteSellingPlan}
            onUpdateSellingPlan={updateSellingPlan}
            setSellingPlanInputs={setSellingPlanInputs}
          />
          {Boolean(sellingPlanGroupInput.id) && (
            <LegacyStack vertical>
              <SellingPlanProducts
                sellingPlanGroupName={sellingPlanGroupInput?.storefrontLabel}
                productIds={productIds}
                variantIds={variantIds}
                setProductIds={setProductIds}
                setVariantIds={setVariantIds}
                reloadDeliveryProfile={reloadDeliveryProfile}
              />
              <SellingPlanBundle bundles={bundleConfigs} loading={isBundleConfigsLoading} />
              {numericGroupId ? <SequentialOffering /> : null}
            </LegacyStack>
          )}
          <Layout>
            {Boolean(errors.length) && (
              <Layout.Section>
                <Banner title="Errors occurred during submission" status="critical">
                  {errors.map(({ field, message }) => (
                    <p key={field?.join()}>{message}</p>
                  ))}
                </Banner>
              </Layout.Section>
            )}
          </Layout>
          <BottomButtonGroup isDeleteButton={Boolean(sellingPlanGroupInput.id)}>
            {Boolean(sellingPlanGroupInput.id) && (
              <Button outline destructive onClick={onDeleteSellingPlanGroup}>
                Delete program
              </Button>
            )}
            <Button id="admin-selling-plan-group__save" primary onClick={onSave}>
              Save
            </Button>
          </BottomButtonGroup>
        </LegacyStack>

        <Modal
          title="Warning"
          open={showLinkedBundleModal}
          onClose={closeLinkedBundleModal}
          primaryAction={{
            content: "Go Back",
            onAction: closeLinkedBundleModal,
          }}
          secondaryActions={[
            {
              content: "Delete Program",
              onAction: closeLinkedBundleModal,
              disabled: true,
            },
          ]}
        >
          <Modal.Section>
            <DeleteBundleBanner />
          </Modal.Section>
        </Modal>

        <Modal
          title={confirmationModalTitle}
          open={isConfirmationModalOpen}
          onClose={() => {
            closeConfirmationModal();
          }}
          primaryAction={{
            content: "Go Back",
            onAction() {
              closeConfirmationModal();
            },
          }}
          secondaryActions={[
            {
              destructive: true,
              content: confirmationModalConfirmActionMessage,
              onAction() {
                confirmationModalFunction();
                closeConfirmationModal();
              },
              disabled: !isEmpty(bundleConfigs),
            },
          ]}
        >
          <Box className="removal-confirmation" p={2}>
            <RemoveModalStyledDiv>
              <div>
                {isEmpty(bundleConfigs) ? null : <DeleteBundleBanner />}
                <Banner
                  title=""
                  action={{ content: "Open all in new tabs", onAction: openInNewTab }}
                  status="warning"
                >
                  <p>{confirmationModalMessage}</p>
                  &nbsp;
                  <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                    {confirmationModalSubscriptions.map(item => {
                      return (
                        <li style={{ color: "#2C6ECB" }} key={item.shopifyId}>
                          <Link
                            onClick={() =>
                              frontEndTabOpen(
                                `${adminRoutePrefix}/subscriptions/${viewShopifyId(item.shopifyId)}`
                              )
                            }
                          >
                            {capitalize(item.custRel?.firstName)} {capitalize(item.custRel?.lastName)} - #
                            {viewShopifyId(item.shopifyId)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </Banner>
              </div>
            </RemoveModalStyledDiv>
          </Box>
        </Modal>
      </Page>
    </SequentialGroupContext.Provider>
  );
}
