export const localizeDefault = {
  general: {
    // Used in two or more sections
    startShopping: "Start Shopping",
    startShoppingLink: "/collections/all",
    itemCount_one: "**count** item",
    itemCount_other: "**count** items",
    point: "point",
    points: "points",
    subtotal: "Subtotal",
    discount: "Discount",
    free: "Free",
    prepaid: "Prepaid",
    add: "Add",
    remove: "Remove",
    unavailable: "Unavailable",

    addedConfirm: "Added!",
    confirmBtn: "Confirm",
    nextBtn: "Next",
    goBackBtn: "Go back",
    confirmChangesBtn: "Confirm changes",
    cancelBtn: "Cancel",
    closeBtn: "Close",
    backBtn: "Back",
    backToAccountBtn: "Back to account",
    backToMangeOrderBtn: "Back to manage order",
    backToUpcomingOrdersBtn: "Back to upcoming orders",
    contactSupportBtn: "Contact support",
    subscriptionNum: "Subscription #**num**",
    copied: "Copied!",
    copiedToClipboard: "Copied to clipboard",
    nullProductsFound: "No products found",
    updatedToast: "Successfully updated",
    addItemBtn: "Add item",
  },
  header: {
    customerSince: "Customer Since",
    tabs: {
      subscriptions: "Subscriptions",
      orderHistory: "Order History",
      settings: "Settings",
      loyalty: "Loyalty",
      trending: "Trending",
      social: "Social",
      reviews: "Reviews",
      logOut: "Log Out",
    },
  },
  tabs: {
    subscriptionTab: "Subscriptions",
    nextOrderTab: "Next Order",
  },
  errorMessages: {
    largeTitle: "Oops!",
    body: "Something went wrong. Please refresh your page or contact support if the issue persists.",
    viewLogs: "View logs",
    hideLogs: "Hide logs",
    contactSupportSubject: "[Customer #**customerShopifyId**] Subscription Plan Error",
    phoneNumber: "Error: invalid phone number",
  },
  customerActions: {
    sendNow: "Order now",
    skip: "Skip",
    unskip: "Unskip",
    gift: "Gift",
    ungift: "Ungift",
    swapItem: "Swap item",
    delayDate: "Delay date",
    editAddress: "Edit address",
    moreActions: "More actions",
    editFreq: "Edit<br/>frequency",
    setDate: "Set next date",
    editPayment: "Edit payment",
    pause: "Pause",
    unpause: "Resume",
    cancel: "Cancel",
  },
  subscriptions: {
    title: "Subscriptions",
    zeroState: "You don't have any subscriptions yet.",
    viewSubscriptions: "View other subscriptions",
    loadBundlesErrorToast: "Error loading bundle data",
    invalidQuantityToast: "Invalid quantity",
    yourNextOrder: {
      title: "Your next order",
      orderSkipped: "Order skipped",
      manageNextOrder: "Manage next order",
      viewImportantNotice: "View important notice",
      giftedLabel: "Gifted",
      seeUpcoming: "See all upcoming orders",
      ungiftBtn: "Ungift",
      failedBillsNotice: "⚠ Action Needed - Transaction Failure",
    },
    yourSubscriptions: {
      title: "Your subscriptions",
      every: "Every **deliveryFrequency**",
      manageSubscriptions: "Manage subscription",
      resumeSubscriptions: "Resume subscription",
      orderOptionsModal: {
        modalTitle: "Manage your next order",
        addItem: "Add",
        adjustmentNoticeNextOrder: "Change will be applied to next order only. To change all upcoming orders,",
        adjustmentNoticeThisOrder: "Change will be applied to this order only. To change all upcoming orders,",
        manageSubscriptionCopy: "manage your subscription.",
        removeItemQtyErrorToast: "Must have at least 1 item in subscription",
        removedItemToast: "Item removed!",
        removeItemErrorToast: "Error removing item",
        invalidDiscountCodeErrorToast: "Enter a valid discount code",
        missingDiscountCodeErrorToast: "Please enter a discount code",
        discountCodeSuccessToast: "Discount code **code** applied",
        cancelSubscription: {
          plainText: "<0>Last subscription item can't be removed. Are you trying to</0> <1>**linkText**</1>?",
          linkText: "cancel your subscription",
        },
        discountCodeRemovedToast: "Discount code removed",
        discountCodeRemovedErrorToast: "Error removing discount code",

        shipTo: "Ship to",
        giftedTo: "Gifted To",
        subtotal: "Subtotal",
        shipping: "Shipping",
        estimatedTotal: "Estimated Total",
        savedBySubscription: "You're saving **amount** by being a subscriber",
        savedByPrepaid: "You prepaid for this subscription and saved **amount**",
        estimatedTotalInformation: "Estimated total does not reflect shipping and tax",
        discountInput: "Discount code",
        applyBtn: "Apply",
        addonTitle: "Select item to add",
        addItemView: {
          addedProductToast: "Added **productName**",
          adjustmentNoticeNextOrder: "Item will be added to next order only. To edit all upcoming orders,",
          adjustmentNoticeThisOrder: "Item will be added to this order only. To edit all upcoming orders,",
          adjustmentSubscriptionNotice: "Item will be added to all upcoming orders.",
          manageSubscription: "manage your subscription.",
        },
        sendNowView: {
          sendNow: "Order now?",
          sendNowToast: "Ordering now!",
          willBeProcessed: "Your order will be processed ",
          willBeProcessedNextTwentyFourHours: "in the next 24 hours.",
          willBeProcessedOnDate: "on **date**.",
          processingOrder: "We're getting ready to process your order.",
          sendingNow: "Ordering now!",
        },
        skipView: {
          skip: "Skip?",
          unskip: "Unskip?",
          skippedToast: "Skipped!",
          unskippedToast: "Unskipped!",
          errorSkipToast: "Error: Unable to skip delivery",
          errorUnskipToast: "Error: Unable to unskip delivery",
          skipBody: "Your next order will be on:",
          unskipBody: "This order will be processed on:",
        },
        giftView: {
          firstNameField: "First name",
          lastNameField: "Last name",
          address1Field: "Address line 1",
          address2Field: "Address line 2",
          cityField: "City",
          stateSelect: "State",
          zipField: "Zip code",
          countrySelect: "Country",
          emailField: "Email address",
          giftMessageHeader: "Gift message",
          giftConfirmedBody: "Your gift to **name** will process on:",
          ungift: "Ungift?",
          ungiftBody: "Your order will no longer be sent to **name**.",
          ungiftConfirmedBody: "Your order has been ungifted.",
          giftedToast: "Gifted!",
          ungiftedToast: "Ungifted!",
          emailErrorToast: "Please enter recipient's email",
        },
        delayDateView: {
          delayOneBtn: "Delay 1 week",
          delayTwoBtn: "Delay 2 weeks",
          delayThreeBtn: "Delay 3 weeks",
          customDateBtn: "Set custom date",
          nextOrderDisplay: "Your new order date will be **date**",
          dateChangedToast: "Date changed!",
          dateChangedToastCalOnly: "Date updated!",
        },
        swapItemView: {
          swapOutTitle: "Select item to swap out",
          swapInTitle: "Select item to swap in",
          swappedToast: "Swapped!",
          singleSwapTitle: "You're swapping out",
          swappingErrorToast: "Error: Invalid line item provided",
          manageSubscription: "manage your subscription.",
          adjustmentNoticeNextOrder: "Swap will be applied to next order only. To edit all upcoming orders,",
          adjustmentNoticeThisOrder: "Swap will be applied to this order only. To edit all upcoming orders,",
        },
        editAddressView: {
          errorToast: "Error: invalid shipping address",
          giftAddressToast: "Gift address changed!",
          duplicateAddressToast: "Error: This address has already been saved",
        },
        subPlanDeletedHeader: "Important notice",
        subPlanDeletedBody:
          "The subscription plan you are currently subscribed to is no longer offered by the shop. You can't currently make any changes to this subscription, but you'll still receive your shipments as shown below. If you'd like to make edits to this subscription, please contact support for assistance.",
        changePlanBtn: "Change my plan",
        selectNewPlan: "Select a new plan",
        subPlanChangedToast: "Subscription changed!",
        noPlans: "No plans available, please contact support for assistance",
      },
      upcomingOrdersModal: {
        modalTitle: "Upcoming orders",
        managingOrder: "Managing order",
        addToCalBtn: "Add to calendar",
      },
      manageSubscriptionModal: {
        shipsEvery: "Ships every **deliveryFrequency**",
        singleVntOOS: "**- vnt** is currently out of stock. ",
        multiVntOOS: "**- vnt** and **overflowNum** others are currently out of stock. ",
        removeItemSingle: "To receive this order add a different item and remove the out of stock item.",
        removeItemMultiple: "To receive this order add a different item and remove the out of stock items.",
        editFreqView: {
          successToast: "Frequency updated!",
          errorToast: "Error updating frequency",
          saveCaption: "Save **discountAmt**",
        },
        setNextDateView: {
          nextOrderDisplay: "Your upcoming orders will be updated based on this new date: **date**",
          dateUpdatedToast: "Date updated!",
        },
        cancelView: {
          optionalText: "Optional",
          noRetentionTitle: "Cancel?",
          retentionTitle: "Let us know why you'd like to cancel",
          body: "You won't be able to reactivate this subscription.",
          pauseText: "<0>If you prefer,</0> <1>you can pause this subscription</1>.",
          textAreaPlaceholder: "Tell us a bit more?",
          canceledToast: "Subscription canceled",
          continueCancelMessage: "No, I still want to cancel my subscription.",
          giftModalMessage: "Would you like to gift your next order to a friend?",
          swapModalMessage: "Would you like to swap an item in your next order?",
          frequencyUpdateModalMessage: "Would you like to edit the frequency of your orders?",
          changeDateModalMessage: "Would you like to change the date of your next subscription order?",
          changeAddressModalMessage: "Would you like to edit the delivery address for your subscription?",
          discountModalMessage: "Would you like an additional **percentage**% off your next order?",
          skipModalMessage: "Would you like to skip your next order?",
          cancelOptionsTitle: "Before you go...",
          discountCodeSuccessToast: "**discountCode**% discount applied to next order",
          discountCodeFailureToast: "Discount code failed",
          prepaidNotice:
            "<0>Since your subscription has been prepaid, you will still receive any deliveries remaining that you've already paid for. However, these remaining deliveries</0> <1>won't</1> <2>appear in your upcoming orders list.</2>",
          cancellationReason0: "Cancel Reason 1",
          cancellationReason1: "Cancel Reason 2",
          cancellationReason2: "Cancel Reason 3",
          cancellationReason3: "Cancel Reason 4",
          cancellationReason4: "Cancel Reason 5",
          cancellationReason5: "Cancel Reason 6",
          cancellationReason6: "Cancel Reason 7",
          cancellationReason7: "Cancel Reason 8",
          pauseReason0: "Pause Reason 1",
          pauseReason1: "Pause Reason 2",
          pauseReason2: "Pause Reason 3",
          pauseReason3: "Pause Reason 4",
          pauseReason4: "Pause Reason 5",
          pauseReason5: "Pause Reason 6",
          pauseReason6: "Pause Reason 7",
          pauseReason7: "Pause Reason 8",
          buttons: {
            gift: "Gift Order",
            swap: "Swap",
            editFrequency: "Edit Frequency",
            changeDate: "Change Date",
            getDiscount: "Get Discount",
            editAddress: "Edit Address",
            skipOrder: "Skip Order",
          },
        },
        editPaymentView: {
          title: "Check your email",
          body1: "We've sent an email to",
          body2: "with instructions on how to update your payment.",
          sentToast: "Email sent!",
        },
        pauseView: {
          changePauseDateTitle: "Change auto-resume date",
          selectPauseDateTitle: "Select auto-resume date",
          resumeDateCaption: "Set to auto-resume on",
          resumeDateCaptionButton: "Change date?",
          pauseWithDateBody:
            "Please select a future date for your subscription to auto-resume. If you change your mind, you'll be able to come back here to resume your subscription immediately at any time.",
          pauseTitle: "Pause",
          pauseReasonTitle: "Let us know why you'd like to pause?",
          pauseBody: "You'll be able to resume your subscription at any time.",
          pauseToast: "Subscription paused",
          pauseResumeDateUpdatedToast: "Auto-resume date updated",
          pauseDateErrorToast: "Select a resume date",
          pauseErrorToast: "Error pausing subscription",
          unpauseTitle: "Resume?",
          unpauseBody: "Your subscription will be immediately unpaused.",
          unpauseToast: "Subscription resumed",
          unpauseErrorToast: "Error unpausing subscription",
        },
        editAddress: {
          newAddressDisclaimer: "New address will be applied to all upcoming orders.",
          changeAddressForOrderOnly: "Click here to edit your next order only.",
          updatedAddress: "Address changed!",
        },
      },
    },
    addToYourNextOrder: {
      title: "Add to your next order",
      modalTitle: "Select item to add",
      add: "Add",
      search: "Search",
    },
  },
  orderHistory: {
    title: "Order History",
    pastOrders: "Past orders",
    zeroStateForYear: "No orders this year.",
    zeroState: "You don't have any order history yet.",
    subscription: "Subscription",
    order: "Order",
    subtotal: "Subtotal",
    shipping: "Shipping",
    totalTax: "Total tax",
    total: "Total",
    billedTo: "Billed to",
    paid: "Paid",
    shippedTo: "Shipped to",
    shippingMethod: "Shipping method",
    tracking: "Tracking",
    contactCarrier: "Contact carrier",
    contactShop: "Contact shop",
    giftedTo: "Gifted to",
    refundAmount: "Refund amount",
    refundedTo: "Refunded to",
    prepaidDelivery: "Prepaid delivery **firstValue** of **secondValue**",
    trackShipment: "Track shipment **firstValue** of **secondValue**",
    savedBySubscription: "You saved **amount** by being a subscriber",
    savedByPrepaid: "You prepaid for this subscription and saved **amount**",
    anchorWeek: "Delivers every **anchor**",
    anchorMonth: "Delivers every **anchor** of the month",
    anchorYear: "Delivers every **anchorMonth**, **anchorDay**",
    finiteMaxCycles: "Ends **date**",
    finiteMinCycles: "Thru **date**, then auto-renews",
    gifted: "Gifted",
    partiallyGifted: "Partially gifted",
    refunded: "Refunded",
    partiallyRefunded: "Partially refunded",
    partiallyShipped: "Partially shipped",
    shipped: "Shipped",
    processing: "Processing",
    canceled: "Canceled",
    freeShipping: "Free shipping",
  },
  selectModal: {
    modalTitle: "What would you like to manage?",
    nextOrderTitle: "Only next order",
    upcomingOrderTitle: "All upcoming orders",
  },
  editBundle: {
    edit: "Edit",
    packQty: "**qty** pack",
    yourSize: "Your size",
    modalTitle: "Edit your box",
    bundleSize: "Select size",
    saveAmt: "Save **amt**",
    selectDifferentSize: "Select different size",
    addItemsTitle: "Add **minNum** items",
    boxFull: "Box full!",
    clear: "Clear all",
    maxItemsErrorToast: "Maximum items in box",
    missingItemsToast: "Please add **num** item",
    missingItemsToast_plural: "Please add **num** items",

    bundleUpdatedToast: "Box updated",
    errorUpdatingBundleToast: "Error updating box, please try again",
    errorGettingCollectionToast: "Error getting collection, please reload and try again",
  },
  settings: {
    title: "Settings",
    shippingAddress: {
      title: "Shipping address",
      noAddressSet: "No address set",
      changeAddress: "Change address",
      addAddressBtn: "Add a new address",
      newAddressHeader: "New address",
      editAddressHeader: "Edit Address",
      giftAddressHeader: "Recipient details",
      setDefaultCheckBox: "Set as default address",
      setAddressDisclaimer: "This will update your default address for any active subscriptions.",
      addedToast: "Address added",
      invalidAddress: "Invalid shipping address",
      updatedToast: "Address updated",
      updatedDefaultToast: "Default address may take a few moments to update",
      deletedToast: "Address deleted",
      errorDeletingAddress: "Error deleting address",
      deleteTooltip: "Delete",
      editTooltip: "Edit",
      setDefaultTooltip: "Set default",
      defaultAddressToast: "Default address may take a few moments to update.",
      deleteView: {
        title: "Delete?",
        body: "This address will be removed from your account.",
      },
    },
    paymentMethod: {
      title: "Payment method",
      changePaymentMethod: "Change payment method",
    },
    personalInformation: {
      title: "Personal information",
      changeEmail: "Change email",
      birthdayModalTitle: "Add birthday",
      birthdayTitle: "Enter your birthday",
      changePassword: "Change password",
      newEmail: "Enter your new email",
      emailUpdateError: "Email already taken",
      emailUpdated: "Email updated!",
      addBirthday: "Add birthday",
    },
  },
  loyalty: {
    title: "Loyalty",
    pointBalance: "**unitName** balance:",
    rewardReady: "Your reward is ready",
    redeem: "Redeem",
    redeemVariant: "Redeem **name**?",
    redeemIncentivePercentage: "Redeem **amount****unitName** off?",
    redeemIncentiveAmount: "Redeem **unitName****amount** off?",
    redeemIncentiveConfirmationPercentage:
      "<0>Use the below code on your next order for </0><1>**amount****unitName**</1><2> off your next order:</2>",
    redeemIncentiveConfirmationAmount:
      "<0>Use the below code on your next order for </0><1>**unitName****amount**</1><2> off your next order:</2>",
    redeemDeduction: "<0>This will deduct</0> <1>**amount** **unitName**</1> <2>from your point balance.</2>",
    getPoints: "How do I earn **unitName**?",
    yourFriendGets: "Your friend gets",
    yourFriendGetsUnitsOfValue: "**unitName****amount** off",
    yourFriendGetsPercentOfValue: "**amount****unitName** off",
    youGet: "You get",
    referralLink: "Copy my referral link",
    referralCode: "Copy my referral code",
    maximumReferralsReached: "Maximum referrals reached",
    redeemForPoints: "Redeem for **costInPoints** **unitName**?",
    willBeAdded: "will be added to your next order on:",
    spentEqualsPoints: "**currency**1 spent = **amount** **unitName**",
    rewardRedeemed: "**-name** redeemed!",
    noUpcomingDelivery: "You don't have any upcoming deliveries!",
    orders: "Orders",
    pointsPerSpent: "**amount** **unitName** per **currency**1 spent",
    createAccount: "Creating an account",
    referringAFriend: "Referring a friend",
    referralLinkNotFound: "Referral link was not found!",
    displayPoints: "**amount** **unitName**",
    pointOnEveryXOrder: "**amount** **unitName** every **xOrder** order",
    notSupported: "Event is not supported currently",
    bonusEarn: "Bonus earn",
    xPercentOffYourNextOrder: "**amount**% off your next order",
    xUnitsOffYourNextOrder: "**unitName****amount** off your next order",
    xPercentOff: "**amount**% off",
    xUnitsOff: "**unitName****amount** off",
    xOffYourNextOrder: "**amount****unitName** off your next order",
    addCreditToOrderToDate: "credit will be added to your next order on: ",
    copy: "Copy",
    copyCode: "Copy code",
    codeConfirmedVariant:
      "<0>Use the code below on your next order for a free </0><1>**productName**</1><2>:</2>",
    codeUnique: "This code is unique to your account and can't be used by others.",
    cartVariantAdd: "<0>or, </0><1>click here</1><2> to add directly to bag and checkout with code.</2>",
    sendToMail: "Send code by email",
    description: "Description",
    unitSingular: "point",
    unitPlural: "points",
    incentiveAlreadyRedeemedTooltip: "Only one incentive can be redeemed per order",
    alreadyRedeemedTooltip: "Reward can only be redeemed once per order",
    subscriberOnly: "Subscriber exclusive",
    redeemUnpause: "<0>Redeem</0> <1>**productName**</1>",
    redeemUnpauseDate:
      "<0>Your subscription is paused until </0><1>**date**</1><2>. If you redeem an item, it will be added to your next subscription order. Would you like your subscription to resume immediately or on </2><3>**date**?</3>",
    resumeImmediatelyText: "**rewardItem** will be added to your next order.",
    resumeOnUnpauseDateText: "<0>**rewardItem**</0><1> will be added to your next order on: </1><2>**date**</2>",
    redeemNoUnpauseDate:
      "Your subscription is paused. If you redeem an item, it will be added to your next subscription order. Would you like your subscription to resume immediately?",
    resumeImmediatelyButton: "Resume immediately",
    resumeOnUnpauseDateButton: "<0>Resume </0> <1> **date**</1>",
    pointsEarned: "**unitName** earned this year:",
    tiers: {
      pointsTillTier: "**unitName** to **tierName** tier for **year**:",
      tierStatusTitle: "**name**, you're at **tierName** tier",
      nextYearStatus: "**year** status: **tierName**",
      maxTier: "You're at the top tier for the year - thank you for being a valued customer!",
      earnPointsToNextTier:
        "Earn more **unitName** by December 31 to reach the next tier and unlock more rewards. Tier status is earned for the current and following year.",
      tierExclusiveTooltip_andUp: "This reward is exclusive to **tierName** tier and up.",
      tierExclusiveTooltip: "This reward is exclusive to **tierName** tier.",
    },
  },
  review: {
    title: "Leave a Review",
    shareYourThoughts: "Share your thoughts",
  },
  social: {
    title: "Social",
    followBtn: "Follow",
    postCount: "**mediaCount** posts",
    viewMore: "View more",
  },
  trending: {
    title: "Trending",
  },
  quickBuy: {
    title: "Recommended for You",
    header: "You might like...",
    buy: "Buy",
  },

  dates: {
    day: "day",
    days: "days",
    week: "week",
    weeks: "weeks",
    month: "month",
    months: "months",
    year: "year",
    years: "years",
    tomorrow: "tomorrow",
  },
  badgeStatus: {
    active: "Active",
    canceled: "Canceled",
    expired: "Expired",
    failed: "Failed",
    paused: "Paused",
    importantNotice: "Important notice",
    current: "Current",
  },
  banner: {
    oosVariantMessage: "**variant** is no longer available.",
    swapOrRemoveVariantMessage: "Please <0>swap</0> or <1>remove</1> this item to resume your subscription.",
    removeVariantMessage: "Please <1>remove</1> this item to resume your subscription.",
    swapVariantMessage: "Please <0>swap</0> this item to resume your subscription.",
    cancelSubscriptionMessage: "Your subscription has been paused until the item is available again.",
    failedPaymentNotice:
      "There was a problem with your payment. Error: **error**. We'll automatically retry your card <0>**remainingRetries**</0> more time(s) before your order is skipped. <1>Click here</1> to update your payment method or <2>contact us</2> for more information.",
    "failedPaymentNotice-with-date":
      "There was a problem with your payment. Error: **error**. We'll automatically retry your card <0>**remainingRetries**</0> more time(s) before your order originally scheduled for **orderDate** is skipped. <1>Click here</1> to update your payment method or <2>contact us</2> for more information.",
    failedPaymentNote:
      "<0>Note:</0> If you try to process your order again manually, you may risk being billed twice. Please continue to wait for our automatic retry even if you've updated your payment method.",
  },
  addressForm: {
    firstName: "First name",
    lastName: "Last name",
    addressOne: "Address line 1",
    addressTwo: "Address line 2",
    city: "City",
    country: "Country",
    state: "State / Province",
    zip: "Zip / Postal code",
    emailAddress: "Email address",
    phoneNumber: "Phone number (required)",
    giftTextareaPlaceholder: "Up to **max** characters",
  },
  delivery: {
    local: "Local delivery",
    pickup: "Pickup",
    shipping: "Shipping",
    shipTo: "Ship to",
  },
  errorFallback: {
    subscription: "Error updating subscription",
  },
  // ** HIDDEN IF OTP IS DISABLED **
  passwordlessLogin: {
    accountLockedError: "Account locked",
    accountTempLocked: "Account temporarily locked. Please try again in",
    codeExpiredError: "Code has expired, please request a new one.",
    codeUsedError: "Code already used",
    magicLinkSuccessHeader: "Check your email",
    enterEmail: "Enter your email",
    magicLinkSuccessBody: "You just received a link to sign in to your account",
    magicLinkSuccessResendLinkBtn: "Resend Link",
    createOne: "Create one**ending**",
    emailPlaceholder: "Your email address",
    enterCode: "Enter code",
    errorFallback: "Error",
    greeting: "Welcome",
    loginBtn: "Login with code",
    loginWithMagicLink: "Sign in",
    newCodeSent: "Please check your email for a new code.",
    noAccount: "No account?",
    noAccountFound: "No account found.",
    notActivatedError: "Account isn't currently active. Please check your email for an account activation link.",
    noEmail: "Please enter email address.",
    notActivatedFailedInviteError: "Account isn't currently active. Please contact support.",
    remainingCharacters: "**num** left",
    resendCode: "Resend my code",
    serverErrorFallback: "Sorry, we're having trouble with your request. Please try again.",
    tooManyOTPError: "Too many attempts",
    tooManyPasswordlessError: "Too many attempts",
    tryAgain: "Please try again in",
    wrongOTPError: "Incorrect code, please try again.",
  },
  // ** HIDDEN **
  utils: {
    dates: {
      toViewDateLongDay: "**date, dddd, MMMM Do**",
      toViewDateLong: "**date, MMMM DD, YYYY**",
      toViewDateShort: "**date, MM/DD/YYYY**",
      toViewDateMonthYear: "**date, MMMM YYYY**",
      toViewDateBirthday: "**date, MMMM Do, YYYY**",
      translateMonth: "**month, MMMM**",
      translateDay: "**day, dddd **",
    },
  },
  // ** FALLBACK / DEFAULT VALUES **
  defaults: {
    dates: {
      weekdays: {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday",
      },
      months: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
      },
    },
  },
};
