import { Button, Card, HorizontalStack, Icon, Link, Page, Text, VerticalStack } from "@shopify/polaris";
import { LockMajor } from "@shopify/polaris-icons";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import styled from "styled-components";

const PageWrapper = styled.div`
  margin-top: 4rem;
`;

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 620px;

  .Polaris-Icon {
    margin: 0;
  }
`;

interface FreeTrialExpiredPageProps {
  pageTitle: string;
}

export const FreeTrialExpiredPage = ({ pageTitle }: FreeTrialExpiredPageProps) => {
  return (
    <PageWrapper>
      <Page title={pageTitle} narrowWidth>
        <CenteredWrapper>
          <Card>
            <VerticalStack align="center" gap="5">
              <HorizontalStack align="start" blockAlign="center" gap="2">
                <Icon source={LockMajor} />
                <Text variant="headingMd" as="h2">
                  Your free trial has expired
                </Text>
              </HorizontalStack>
              <Text variant="bodyMd" as="p">
                Select a plan to access this feature again. If you have any questions, don&#39;t hesitate to{" "}
                <Link onClick={() => navigateWithShopInQuery(`${adminRoutePrefix}/support`)}>
                  reach out to support.
                </Link>
              </Text>
              <HorizontalStack align="start">
                <Button primary onClick={() => navigateWithShopInQuery(`${adminRoutePrefix}/smartrr-account`)}>
                  Select a plan
                </Button>
              </HorizontalStack>
            </VerticalStack>
          </Card>
        </CenteredWrapper>
      </Page>
    </PageWrapper>
  );
};
