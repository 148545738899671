import { ApolloClient, useApolloClient } from "@apollo/client";
import {
  Banner,
  Button,
  Checkbox,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Modal,
  Select,
  Text,
} from "@shopify/polaris";
import { CircleTickMajor, ExternalMinor, ExternalSmallMinor, InfoMinor } from "@shopify/polaris-icons";
import { FRONT_END_HOST_BASE, appPrefixedHost } from "@smartrr/shared/constants";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { ScriptTagDisplayScope } from "@smartrr/shared/shopifyGraphQL/api";
import {
  mutationShopifyCreateScriptTag,
  mutationShopifyUpdateScriptTag,
  queryShopifyScriptTags,
} from "@smartrr/shared/shopifyGraphQL/scriptTag";
import { injectLiquid } from "@smartrr/shared/shopifyLiquid/liquidOperations";
import {
  MainProductAssetName,
  smartrrCustomerAccountPageInjection,
  smartrrMainProductDivInjection,
  smartrrMainProductFormIdInjection,
  smartrrMainProductRenderInjection,
  smartrrSnippets,
  smartrrSnippetsWithoutProduct,
} from "@smartrr/shared/shopifyLiquid/templates";
import { getShopifyAsset } from "@smartrr/shared/shopifyRest/asset";
import { captureException } from "@smartrr/shared/utils/captureException";
import { isUndefined } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

import { useRestClient } from "../../../components/auth/RestProviderWrapper";
import {
  SNIPPETS_CARD_BANNER_TITLE,
  SNIPPETS_CARD_TITLE,
  THEME_CONFIRMATION_CONFIRMATION_LABEL,
  THEME_CONFIRMATION_TITLE,
} from "../../libs/constants";
import {
  DisabledButton,
  DocIconWrapper,
  DocsLink,
  DocsLinkContainer,
  DocsLinkWrapper,
  ModalTitle,
  SetupIconContainer,
  SetupIconWrapper,
  SmartrrSnippetsCard,
  SubscriptionWidgetBanner,
  TabHeader,
} from "../../libs/styles/index";
import { getAsset, updateAsset, updateCustomerPortalToken } from "../../libs/utils";
import { formatProductString } from "../../libs/utils/formatProductString";
import { getThemeSelectOptions } from "../../libs/utils/getThemeSelectOptions";
import { SetupStoreAccess } from "../../libs/store";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ISetup } from "@smartrr/shared/entities/Organization";

export const OnsiteSetup = () => {
  const setup = SetupStoreAccess.useSetup();
  if (!setup) {
    return <React.Fragment />;
  }
  return <OnsiteSetupInner setup={setup} />;
};

const OnsiteSetupInner = ({ setup }: { setup: ISetup }) => {
  const restClient = useRestClient();
  const apolloClient = useApolloClient();
  const { addToast } = useToast();
  const organization = useActiveOrganizationSelector();

  const actions = SetupStoreAccess.useActions();
  const themes = SetupStoreAccess.useThemes();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const [isThemeInstallationLoading, setIsThemeInstallationLoading] = useState<boolean>(false);
  const [isWdigetEnabled, setIsWdigetEnabled] = useState(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [chosenThemeId, setChosenThemeId] = useState<string>("");
  const [showSmartrrWidgetBanner, setShowSmartrrWidgetBanner] = useState<boolean>(true);
  const [doesMainThemeSupportAppBlocks, setDoesThemeSupportAppBlocks] = useState<boolean>();
  const shopUrl = organization?.myShopifyDomain;
  const selectOptions = getThemeSelectOptions(setup.snippetsThemeId, themes);
  const formattedProductString = formatProductString(purchasables);

  useEffect(() => {
    if (setup.snippetsThemeId) {
      const theme = themes.find(theme => theme.id === +setup.snippetsThemeId);
      setChosenThemeId(String(theme?.id));
      checkIfThemeIsCompatible(+setup.snippetsThemeId);
    }
  }, []);

  const handleChange = useCallback((newChecked: boolean) => setIsWdigetEnabled(newChecked), []);

  const currentTheme = useMemo(() => {
    return themes.find(theme => theme.id === +chosenThemeId);
  }, [themes, chosenThemeId]);

  async function injectOrderStatusScriptTag(
    apolloClient: ApolloClient<object>,
    addToast: (content: string) => void
  ) {
    const orderStatusScriptSrc = `https://${appPrefixedHost}/js/smartrr-order-status.js`;
    const orderStatusLegacyScriptSrc = `https://${FRONT_END_HOST_BASE}/js/smartrr-order-status.js`;
    const [queryRes, legacyQueryRes] = await Promise.all([
      queryShopifyScriptTags(apolloClient, 1, orderStatusScriptSrc),
      queryShopifyScriptTags(apolloClient, 1, orderStatusLegacyScriptSrc),
    ]);
    if (queryRes.type === "error" || legacyQueryRes.type === "error") {
      const errorMessage = "Error fetching script tags";
      captureException(errorMessage);
      addToast(errorMessage);
      return;
    }

    const scriptTags = queryRes.body.data.scriptTags.edges.map(({ node }: any) => node);
    const legacyScriptTags = legacyQueryRes.body.data.scriptTags.edges.map(({ node }: any) => node);
    if (legacyScriptTags.length) {
      // updates legacy script tag if that exists
      return mutationShopifyUpdateScriptTag(
        legacyScriptTags[0].id,
        {
          src: orderStatusScriptSrc,
          displayScope: ScriptTagDisplayScope.OrderStatus,
        },
        apolloClient
      );
    } else if (scriptTags.length) {
      // updates script tag if that exists
      return mutationShopifyUpdateScriptTag(
        scriptTags[0].id,
        {
          src: orderStatusScriptSrc,
          displayScope: ScriptTagDisplayScope.OrderStatus,
        },
        apolloClient
      );
    }
    return mutationShopifyCreateScriptTag(
      {
        src: orderStatusScriptSrc,
        displayScope: ScriptTagDisplayScope.OrderStatus,
      },
      apolloClient
    );
  }

  const onPublishToTheme = useCallback(
    async (themeId: number, themeName: string) => {
      setIsThemeInstallationLoading(true);
      let mainProductValue: string;

      const snippetInjections = [
        ...(isWdigetEnabled ? [] : smartrrSnippets),
        ...smartrrCustomerAccountPageInjection,
        ...(isWdigetEnabled ? [] : smartrrMainProductFormIdInjection),
        ...(isWdigetEnabled ? [] : smartrrMainProductDivInjection),
        ...(isWdigetEnabled ? smartrrSnippetsWithoutProduct : []),
        ...(isWdigetEnabled ? [] : smartrrMainProductRenderInjection),
      ];

      const responses = await Promise.all([
        updateCustomerPortalToken(themeId),
        ...snippetInjections.map(async ({ name, snippet, replacePosition, toReplace, toRemoveBefore }) => {
          let assetValue = "";
          try {
            //liquid theme file code as string
            assetValue = await getAsset(restClient, themeId, name);
          } catch (error) {
            // if getAsset throws assume asset doesn't exist and needs to be created
            captureException("Unable to fetch liquid theme", error);
          }

          let mainProductValueCheck = assetValue;

          // Because we inject into main-product multiple times at different positions in the loop, we need to keep track of the value of the updated theme file
          if (name === MainProductAssetName.SECTIONS_MAIN_PRODUCT_TEMPLATE) {
            mainProductValueCheck = mainProductValue ?? assetValue;
          }

          const newValue = injectLiquid(
            name,
            mainProductValueCheck,
            snippet,
            toReplace,
            replacePosition,
            toRemoveBefore
          );

          if (name === MainProductAssetName.SECTIONS_MAIN_PRODUCT_TEMPLATE && newValue) {
            mainProductValue = newValue;
          }

          if (newValue === undefined) {
            const error = `Error updating asset ${name}`;
            captureException(error);
            addToast(error);
            setIsThemeInstallationLoading(false);
            setModalOpen(false);
            return;
          }

          return updateAsset(themeId, name, newValue);
        }),
        injectOrderStatusScriptTag(apolloClient, addToast),
      ]);

      const errors = responses.filter(res => !res || res.type === "error");
      if (errors.length) {
        setModalOpen(false);
        setIsThemeInstallationLoading(false);

        let errorCause;
        if (errors.some(error => error)) {
          errorCause = errors.map(error => (error?.type === "error" && error ? error.message : "")).join(",");
        } else {
          errorCause = "Unable to replace snippet in template";
        }

        const errorMessage = "Error injecting smartrr into one or more liquid files";
        captureException(errorMessage, errorCause);
        addToast(`${errorMessage}:${errorCause}`);
        return;
      }
      actions.update(
        [
          {
            path: "snippetsThemeId",
            val: themeId.toString(),
          },
          {
            path: "onSiteSetup.smartrrSnippets",
            val: {
              completed: true,
              date: ISODateString.toString(new Date()),
              themeName,
              isBannerDismissed: !!setup?.onSiteSetup?.smartrrSnippets?.isBannerDismissed,
            },
          },
        ],
        { installingSnippets: true }
      );

      setIsThemeInstallationLoading(false);
      addToast("Snippet successfully installed");
      setModalOpen(false);
    },
    [themes, chosenThemeId, isWdigetEnabled]
  );

  const checkIfThemeIsCompatible = useCallback(async (themeId: number) => {
    try {
      const assetRes = await getShopifyAsset(themeId, "templates/cart.json", restClient);
      if (assetRes.type === "error") {
        setDoesThemeSupportAppBlocks(false);
        setIsWdigetEnabled(false);
      } else {
        setDoesThemeSupportAppBlocks(true);
        setIsWdigetEnabled(true);
      }
    } catch (error) {
      captureException("Unable to to check if theme is compatible", error);
    }
  }, []);

  const onDismissModal = () => {
    setModalOpen(false);
    setChosenThemeId(setup.renderingThemeId || "");
    setDoesThemeSupportAppBlocks(undefined);
  };

  return (
    <React.Fragment>
      <TabHeader>
        <Text variant="headingLg" as="h2" fontWeight="semibold">
          On-site setup{" "}
        </Text>
        <Text variant="bodyMd" as="span" color="subdued">
          Use the steps below to install Smartrr onto your store&apos;s Shopify theme.{" "}
        </Text>
      </TabHeader>

      <SmartrrSnippetsCard removePaddingTop={!!setup?.onSiteSetup?.smartrrSnippets?.isBannerDismissed}>
        <LegacyCard title={SNIPPETS_CARD_TITLE}>
          <LegacyCard.Section>
            {!setup?.onSiteSetup?.smartrrSnippets?.isBannerDismissed && (
              <Banner
                title={SNIPPETS_CARD_BANNER_TITLE}
                action={{ content: "Go to Shopify", url: `https://${shopUrl}/admin/themes`, external: true }}
                status="info"
                onDismiss={() => {
                  if (setup?.onSiteSetup?.smartrrSnippets) {
                    actions.update([
                      {
                        path: "onSiteSetup.smartrrSnippets",
                        val: {
                          ...setup?.onSiteSetup?.smartrrSnippets,
                          isBannerDismissed: true,
                        },
                      },
                    ]);
                  }
                }}
              >
                <p>
                  {` Installing on a copy of your theme will prevent Smartrr from showing up on your shop before you’re ready. Name it something that is easily recognizable, such as: {Theme name} — Smartrr {date}.`}
                </p>
              </Banner>
            )}
            &nbsp;
            <LegacyStack alignment="center">
              <Button primary loading={isThemeInstallationLoading} onClick={() => setModalOpen(true)}>
                Install snippets
              </Button>
              {!!setup.onSiteSetup?.smartrrSnippets && !!setup.onSiteSetup?.smartrrSnippets?.completed && (
                <SetupIconContainer>
                  <SetupIconWrapper>
                    <Icon source={CircleTickMajor} color="base" />
                  </SetupIconWrapper>
                  <Link
                    target="_blank"
                    url={setup.snippetsThemeId ? `https://${shopUrl}/admin/themes` : undefined}
                  >{`Installed ${ISODateString.fromString(
                    setup.onSiteSetup.smartrrSnippets.date
                  ).toLocaleString()} - ${setup.onSiteSetup?.smartrrSnippets?.themeName}`}</Link>
                </SetupIconContainer>
              )}
            </LegacyStack>
          </LegacyCard.Section>
        </LegacyCard>
      </SmartrrSnippetsCard>

      <LegacyCard title="2. Add Smartrr subscription widget">
        <LegacyCard.Section>
          {!isUndefined(doesMainThemeSupportAppBlocks) &&
            (doesMainThemeSupportAppBlocks ? (
              showSmartrrWidgetBanner && (
                <SubscriptionWidgetBanner>
                  <Banner status="info" onDismiss={() => setShowSmartrrWidgetBanner(false)}>
                    <p>
                      You&apos;re using a Shopify theme that supports the block for our Smartrr Subscription
                      widget.
                    </p>
                  </Banner>
                </SubscriptionWidgetBanner>
              )
            ) : (
              <Banner status="warning" title="">
                <p>
                  It looks like the theme you selected in the above step doesn&apos;t support the block for our
                  Smartrr subscription widget. Use the section below to manually install Smartrr on your site.
                </p>
              </Banner>
            ))}
          <div style={{ marginTop: 10 }}>
            <LegacyStack alignment="center">
              <p>
                Use the Smartrr subscription widget to customize how subscription options appear on product pages.
              </p>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <DisabledButton>
                  <Button
                    icon={ExternalMinor}
                    primary
                    url={
                      !!setup.snippetsThemeId && !!formattedProductString
                        ? `https://${shopUrl}/admin/themes/${setup.snippetsThemeId}/editor?previewPath=/products/${formattedProductString}`
                        : undefined
                    }
                    disabled={!isUndefined(doesMainThemeSupportAppBlocks) && !doesMainThemeSupportAppBlocks}
                    external
                    onClick={() => {
                      if (setup.snippetsThemeId) {
                        actions.update(
                          [
                            {
                              path: "onSiteSetup.smartrrWidget",
                              val: {
                                completed: true,
                              },
                            },
                          ],
                          { installingWidgets: true }
                        );
                      } else {
                        addToast("Install Smartrr snippets first");
                      }
                    }}
                  >
                    Add Smartrr subscription widget
                  </Button>
                </DisabledButton>
              </div>
            </LegacyStack>
          </div>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <LegacyStack vertical>
            <Text variant="headingMd" as="h3">
              Manual snippet installation
            </Text>
            <p>
              Click below for instructions on how to manually render Smartrr on your theme&apos;s product page.
              Note: these instructions will involve some manipulation of the source theme code on your Shopify
              site.
            </p>
            <Button
              primary={!isUndefined(doesMainThemeSupportAppBlocks) && !doesMainThemeSupportAppBlocks}
              onClick={() =>
                navigateWithShopInQuery(
                  `https://help.smartrr.com/docs/getting-started/implementation/how-do-i-install-smartrr-on-a-modified-or-custom-shopify-theme-guided-install`,
                  undefined,
                  undefined,
                  true
                )
              }
            >
              Guided install
            </Button>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>

      <DocsLinkContainer>
        <LegacyStack distribution="center" spacing="tight">
          <Icon source={InfoMinor} color="highlight" />
          <DocsLinkWrapper>
            Still need help?&nbsp;
            <DocsLink href="https://help.smartrr.com/docs/" rel="noreferrer" target="_blank">
              Read our help documentation.
              <DocIconWrapper>
                <Icon source={ExternalSmallMinor} color="highlight" />
              </DocIconWrapper>
            </DocsLink>
          </DocsLinkWrapper>
        </LegacyStack>
      </DocsLinkContainer>

      <Modal
        open={modalOpen}
        onClose={() => onDismissModal()}
        title={<ModalTitle>{THEME_CONFIRMATION_TITLE}</ModalTitle>}
        primaryAction={{
          content: "Confirm",
          onAction() {
            if (chosenThemeId) {
              onPublishToTheme(+chosenThemeId, currentTheme?.name || "");
            } else {
              addToast("Select a theme from the dropdown first.");
            }
          },
          loading: isThemeInstallationLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => onDismissModal(),
          },
        ]}
      >
        <Modal.Section>
          <LegacyStack vertical>
            <Select
              label={""}
              value={String(chosenThemeId)}
              options={[
                ...(setup.snippetsThemeId
                  ? []
                  : [
                      {
                        label: "Select theme",
                        value: "",
                      },
                    ]),
              ].concat(selectOptions)}
              onChange={newValue => {
                if (newValue) {
                  checkIfThemeIsCompatible(+newValue);
                  setChosenThemeId(newValue);
                }
              }}
            />
            <Checkbox
              label={THEME_CONFIRMATION_CONFIRMATION_LABEL}
              checked={isWdigetEnabled}
              onChange={handleChange}
            />
            <Text variant="bodyMd" as="span" color="subdued">
              Uncheck this box if you are planning on having Smartrrs implementation team or an in-house developer
              create a custom PDP subscription widget for your shop. You can redo this step on a different theme
              at any point if you change your mind.
            </Text>
            <Banner status="info" title="">
              {doesMainThemeSupportAppBlocks ? (
                <p>
                  You&apos;re using a Shopify theme that supports the block for our &apos;Smartrr subscription
                  widget. We recommend leaving the above option checked.
                </p>
              ) : (
                <p>
                  Since your theme doesn&apos;t support the block for our Smartrr subscription widget, we
                  recommend leaving the above option unchecked.
                </p>
              )}
            </Banner>
          </LegacyStack>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
};
